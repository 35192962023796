<!-- 实时数据分类选择器 -->
<template>
  <el-cascader
    ref="orgCascader"
    v-model="selectedOptions"
    v-loading="queryData.loading"
    :options="options"
    clearable
    :disabled="disabled"
    filterable
    placeholder="请选择实时数据分类"
    :props="{
      emitPath: false,
      multiple: multiple,
      checkStrictly: checkStrictly,
      value: value,
      label: label,
      children: children
    }"
  />
</template>

<script setup>
import { ref, reactive, watch, getCurrentInstance, onMounted } from 'vue';
import dataCategory from '@/model/entity/mqtt/dataCategory';
import { dataCategoryPageInfo } from '@/api/mqtt/dataCategory';

const { proxy } = getCurrentInstance(),
  emits = defineEmits(['update:modelValue']),
  props = defineProps({
    //是否可操作
    disabled: {
      type: Boolean,
      default: false
    },
    //选中对象的值
    modelValue: {
      type: String,
      default: null
    },
    checkStrictly: {
      type: Boolean,
      default: false
    },
    //是否多选
    multiple: {
      type: Boolean,
      default: false
    },
    //key值
    value: {
      type: String,
      default: 'id'
    },
    //标签值
    label: {
      type: String,
      default: 'name'
    },
    //children 子项
    children: {
      type: String,
      default: 'children'
    }
  }),
  orgCascader = ref(), //ref
  options = ref([]), //数据数组
  selectedOptions = ref(), //当前选择对象值
  queryData = reactive(dataCategory.query({})),
  /**	获取机构数据*/
  getOrgList = () => {
    //获取列表数据
    proxy.$apiLoding
      .queryLoding(queryData, dataCategoryPageInfo)
      .then((res) => {
        if (res.success) {
          options.value = res.data.records;
        }
      });
  },
  //选择节点监听
  getCheckedNodes = (onlyChind = false) => {
    return orgCascader.value.getCheckedNodes(onlyChind);
  },
  handleChange = () => {
    let { id, name } = orgCascader.value.getCheckedNodes()[0].data;

    selectedOptions.value = id;
  };

onMounted(() => {
  getOrgList();
});

//监听函数
watch(
  () => selectedOptions.value,
  (newValue, preValue) => {
    emits('update:modelValue', newValue);
  },
  {
    immediate: true, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);

//监听函数
watch(
  () => props.modelValue,
  (newValue, preValue) => {
    if (props.modelValue) {
      selectedOptions.value = props.modelValue;
    } else {
      selectedOptions.value = null;
    }
  },
  {
    immediate: true, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);
</script>
<style lang="scss" scoped></style>
