import { ElMessage } from 'element-plus';
const baseURL = process.env.VUE_APP_WEBSORKET_API; // api 的 base_url


/**初始化websorket */
export async function initWebSorket (account) {
  console.log('queryLoding', account);
  let res = false,
    socket = null;

  if ('WebSocket' in window) {
    let url = baseURL + account;

    console.log('websorket请求地址', url);
    socket = new WebSocket(url);
  } else {
    // eslint-disable-next-line new-cap
    ElMessage({ message: '当前浏览器不支持websocket', type: 'warning', duration: 1000 });
  }
  if (socket) {
    //连接成功建立的回调方法
    socket.onopen = await function (event) {
      console.log("ws建立连接成功", event);
      res = event.isTrusted;
    };

    //连接关闭的回调方法
    socket.onclose = function (event) {
      console.log("ws连接关闭");
    };
    //接收到消息的回调方法
    socket.onmessage = function (event) {
      console.log("服务器返回消息： ", event.isTrusted);
    };
    //连接发生错误的回调方法
    socket.onerror = function (event) {
      //   alert('websocket通信发生错误！');
    };

    //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
    window.onbeforeunload = function () {
      socket.close();
    };
  }
  return res;
}

