<template>
    <el-form>
    <el-table ref="formBody" :data="detaiList" stripe border height="420" :row-class-name="tableRowClassName" element-loading-text="加载中..." style="width: 100%" @templateTitle="TemplateType">
        <el-table-column label="维度" prop="dimension" align="center" min-width="100" />
            <el-table-column label="化验事项" align="center" min-width="140">
                <template #default="scope">
                    <div class="table-blue">
                        {{ scope.row.matters }}：<br />(标准：{{ scope.row.minValue }}~{{ scope.row.maxValue }})
                    </div>
                </template>
            </el-table-column>
            <el-table-column property="assayReport" label="化验值" align="center">
                <template #default="scope">
                    <el-input v-model="scope.row.assayReport" :disabled="formDisabled" style="text-align:center"></el-input>
                </template>
            </el-table-column>
            <el-table-column property="isStandard" label="是否达标" align="center">
                <template #default="scope">
                    <dict-radio v-model="scope.row.isStandard" :disabled="formDisabled" code="YES_NO" placeholder="请选择是否达标" clearable />
                </template>
            </el-table-column>
    </el-table>
</el-form>
</template>

  <!--历史数据-->
<script setup>
import { ref, getCurrentInstance,watch } from 'vue';

const emits = defineEmits(['update:modelValue','change']),

  props = defineProps({
    list: {
      type: Array,
      default: () =>[]
    }
  });

// 定义属性
let detaiList=ref([]),
  formDisabled=ref();


//监听函数
watch(()=> props.list,(newValue,preValue)=> {
  detaiList.value=newValue;
},
{
  immediate:true,//watch会在创建后立即执行一次
  deep:true //监听深度嵌套的对象属性
});
//监听函数
watch(()=> detaiList.value,(newValue,preValue)=> {
  if (newValue) {
    emits('change', newValue);
  }
},
{
  immediate:true,//watch会在创建后立即执行一次
  deep:true //监听深度嵌套的对象属性
});
</script>
  <style scoped>
  </style>
