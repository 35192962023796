import request from '@/utils/request';
/**实时数据类别 接口 */
/** 分页查询 */
export function dataCategoryPageInfo (data) {
  return request({
    url: `/mqtt/dataCategory/pageTree`,
    method: 'post',
    data: data
  });
}
/** 查询列表 */
export function dataCategoryQueryList (data) {
  return request({
    url: `/mqtt/dataCategory/queryList`,
    method: 'post',
    data: data
  });
}
/** 保存 */
export function dataCategorySave (data) {
  return request({
    url: `/mqtt/dataCategory/save`,
    method: 'post',
    data: data
  });
}
/** 删除 */
export function dataCategoryDelete (id) {
  return request({
    url: `/mqtt/dataCategory/delete/${id}`,
    method: 'get'
  });
}
/** 查询 */
export function getQueryListdataCategory (data) {
  return request({
    url: `/mqtt/dataCategory/queryList`,
    method: 'post',
    data: data
  });
}

