<!-- eslint-disable one-var -->
<template>
    <div style="border: 1px solid #ccc;height: 100%;">
        <Toolbar
            :editor="editorRef"
            :default-config="toolbarConfig"
            :mode="mode"
            style="border-bottom: 1px solid #ccc;"
        />
        <Editor
            v-model="valueHtml"
            :default-config="editorConfig"
            :mode="mode"
            class="editor_custom"
            style="height: 455px; overflow-y: hidden;"
            @on-created="handleCreated"
            @on-change="handleChange"
            @on-destroyed="handleDestroyed"
            @on-focus="handleFocus"
            @on-blur="handleBlur"
            @custom-alert="customAlert"
            @custom-paste="customPaste"
        />
    </div>
</template>

<script setup>
import '@wangeditor/editor/dist/css/style.css';
import { onBeforeUnmount, ref, shallowRef, onMounted,getCurrentInstance,watch } from 'vue';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';

const { proxy } = getCurrentInstance(),
  props = defineProps({
    modelValue: {
      type: String,
      default: ''
    },
    //是否可视
    visible: {
      type: Boolean,
      default: false
    },
    //是否可编辑
    disabled: {
      type: Boolean,
      default: false
    }
  }),
  emits = defineEmits(['update:modelValue']),

  // 编辑器实例，必须用 shallowRef，重要！
  editorRef = shallowRef(),

  // const menu = editor.getAllMenuKeys()
  // const bar = toolbar.getConfig().toolbarKeys
  // 内容 HTML
  valueHtml = ref(props.modelValue);

watch(() => valueHtml.value, newValue => {
  emits('update:modelValue', newValue);
});

watch(() => props.modelValue, newValue => {
  valueHtml.value = newValue;
});
// 模拟 ajax 异步获取内容
onMounted(() => {

});
// eslint-disable-next-line one-var
const headerObj = ref({}),

  toolbarConfig = {
    excludeKeys: [ // 不需要的工具栏选项
    //   'fullScreen',
    //   'code',
    //   'group-video',
    //   'codeBlock',
    //   'bulletedList',
    //   'numberedList',
    //   'blockquote',
    //   'bold',
    //   'italic',
    //   'todo',
    //   'insertImage',
    //   'insertLink',
    //   'emotion']
    ]
  },

  editorConfig = {
    placeholder: '请输入内容...',
    MENU_CONF: {},
    hoverbarKeys: {
      'image': {
      // 清空 image 元素的 hoverbar
        menuKeys: []
      }
    }
  };

// 上传图片
editorConfig.MENU_CONF['uploadImage'] = {
  // 上传图片的接口地址
  server: process.env.VUE_APP_BASE_API+'sys/fileInfo/upload',
  // form-data fieldName ，默认值 'wangeditor-uploaded-image'
  fieldName: 'file',

  // 单个文件的最大体积限制，默认为 2M
  maxFileSize: 5 * 1024 * 1024, // 5M

  // 最多可上传几个文件，默认为 100
  maxNumberOfFiles: 20,

  // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
  allowedFileTypes: ['image/png,image/jpeg,image/jpg'],

  // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
  meta: {
    // token: 'xxx',
    bizType: ''
  },

  // 将 meta 拼接到 url 参数中，默认 false
  metaWithUrl: false,

  // 自定义增加 http  header
  headers: headerObj,

  // 跨域是否传递 cookie ，默认为 false
  withCredentials: true,

  // 超时时间，默认为 10 秒
  timeout: 5 * 1000, // 5 秒
  // 上传之前触发
  onBeforeUpload(file) {
    // file 选中的文件，格式如 { key: file }
    let fileObj = Object.values(file)[0].data;
    const isJPG = fileObj.type === 'image/jpg' || fileObj.type === 'image/jpeg' || fileObj.type === 'image/png';

    if (!isJPG) {
      proxy.$message.warning('图片只能是 JPG、GIF、PNG 格式!');
    }
    // 判断图片宽高
    // 定义 filereader对象

    // 判断图片大小
    // eslint-disable-next-line one-var
    let isLt = fileObj.size / 1024 / 1024 < 5; // 判断是否小于5M

    if (!isLt) {
      proxy.$message.warning('图片大小不能超过5M! 请重新上传');
    }
    if (!isJPG) {
      return false;
    } else if (!isLt) {
      return false;
    }
    return file;

    // 可以 return
    // 1. return file 或者 new 一个 file ，接下来将上传
    // 2. return false ，不上传这个 file
  },
  // 上传进度的回调函数
  onProgress(progress) {
    // progress 是 0-100 的数字
    console.log('progress', progress);
  },
  // 单个文件上传成功之后
  onSuccess(file, res) {
    console.log(`${file.name} 上传成功`, res);
  },
  // 自定义插入图片
  customInsert(res, insertFn) {
    console.log('自定义插入图片',res);
    const fullUrl = process.env.VUE_APP_FILE_API+res.data.url;

    console.log('图片回显路径',fullUrl);
    insertFn(fullUrl, '', '');

  },
  // 单个文件上传失败
  onFailed(file, res) {
    console.log(`${file.name} 上传失败`, res);
  },
  // 上传错误，或者触发 timeout 超时
  onError(file, err, res) {
    console.log(`${file.name} 上传出错`, err, res);
  }
};


// 上传视频
editorConfig.MENU_CONF['uploadVideo'] = {
  // 上传视频接口地址
  // server: usUploadStore().uploadVideo,
  // form-data fieldName ，默认值 'wangeditor-uploaded-video'
  fieldName: 'file',

  // 单个文件的最大体积限制，默认为 10M
  maxFileSize: 5 * 1024 * 1024, // 5M

  // 最多可上传几个文件，默认为 5
  maxNumberOfFiles: 3,

  // 选择文件时的类型限制，默认为 ['video/*'] 。如不想限制，则设置为 []
  allowedFileTypes: ['video/mp4'],

  // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
  // meta: {
  //     token: 'xxx',
  //     otherKey: 'yyy'
  // },

  // 将 meta 拼接到 url 参数中，默认 false
  metaWithUrl: false,

  // 自定义增加 http  header
  headers: headerObj,

  // 跨域是否传递 cookie ，默认为 false
  withCredentials: true,

  // 超时时间，默认为 30 秒
  timeout: 15 * 1000, // 15 秒

  // 视频不支持 base64 格式插入
  // 自定义插入视频
  customInsert(res, insertFn) {
    // res 即服务端的返回结果

    // 从 res 中找到 url ，然后插入视频
    insertFn(res.data.url);
  },
  // 上传之前触发
  onBeforeUpload(file) {
    // file 选中的文件，格式如 { key: file }
    return file;

    // 可以 return
    // 1. return file 或者 new 一个 file ，接下来将上传
    // 2. return false ，不上传这个 file
  },
  // 上传进度的回调函数
  onProgress(progress) {
    // progress 是 0-100 的数字
    console.log('progress', progress);
  },
  // 单个文件上传成功之后
  onSuccess(file, res) {
    console.log(`${file.name} 上传成功`, res);
  },
  // 单个文件上传失败
  onFailed(file, res) {
    console.log(`${file.name} 上传失败`, res);
  },
  // 上传错误，或者触发 timeout 超时
  onError(file, err, res) {
    console.log(`${file.name} 上传出错`, err, res);
  }
};
// 组件销毁时，也及时销毁编辑器，重要！
onBeforeUnmount(() => {
  const editor = editorRef.value;

  if (editor === null) {
    return;
  }
  editor.destroy();
});

// 编辑器回调函数
// eslint-disable-next-line one-var
const handleCreated = editor => {
    console.log('created', editor);
    editorRef.value = editor; // 记录 editor 实例，重要！
    if (props.disabled) {
      editor.disable();
    } else {
      editor.enable();
    }
  },
  handleChange = editor => {
  },
  handleDestroyed = editor => {
    console.log('destroyed', editor);
  },
  handleFocus = editor => {
    console.log('focus', editor);
  },
  handleBlur = editor => {
    console.log('blur', editor);
  },
  customAlert = (info, type) => {
    alert(`【自定义提示】${type} - ${info}`);
  },
  customPaste = (editor, event, callback) => {
    console.log('ClipboardEvent 粘贴事件对象', event);

    // 自定义插入内容
    // editor.insertText('xxx')

    // 返回值（注意，vue 事件的返回值，不能用 return）
    // callback(false) // 返回 false ，阻止默认粘贴行为
    callback(true); // 返回 true ，继续默认的粘贴行为
  };

</script>
<style lang="scss" scoped>
:deep(.w-e-scroll) {
    overflow-y: hidden !important;
}
:deep(.w-e-text-container [data-slate-editor]) {
    overflow-y: auto !important;
    height: 100% !important;
}

</style>

