<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div class="query">
    <div
      id="main"
      class="query-main"
      :style="{
        marginTop: mainTop + 'px',
        height: 'calc( 100% - ' + mainTop + 'px )'
      }"
    >
      <!--上方查询区域-->
      <div
        v-if="showTop"
        class="query-main-top"
        :style="{ height: isAll || !isShow ? maxHeight : '45px' }"
      >
        <el-scrollbar>
          <div class="query-scrollbar">
            <div
              id="queryTop"
              class="left"
              :style="{ width: 'calc( 100% - ' + butWidth + ' )' }"
            >
              <el-form
                ref="queryForm"
                inline
                :model="queryData"
                label-width="93px"
              >
                <!--搜索条件插槽-->
                <slot name="form" />
              </el-form>
            </div>
            <div id="quyBut" class="right">
              <el-button
                v-if="props.showQueryBut"
                style="
                  font-family: 'webfont';
                  background: #3ea88d;
                  color: white;
                "
                icon="search"
                @click="query"
              >
                搜 索
              </el-button>
              <el-button
                v-if="props.showResetBut"
                style="font-family: 'webfont'"
                icon="refresh"
                @click="reset"
              >
                重 置
              </el-button>
              <template v-if="isShow">
                <el-link
                  v-if="!isAll"
                  class="buttom"
                  type="warning"
                  @click="isAll = true"
                >
                  更 多
                  <i class="el-icon-arrow-down el-icon--right" />
                </el-link>
                <el-link
                  v-else
                  type="warning"
                  class="buttom"
                  @click="isAll = false"
                >
                  收 起
                  <i class="el-icon-arrow-up el-icon--right" />
                </el-link>
              </template>
            </div>
          </div>
        </el-scrollbar>
      </div>

      <!--下方内容区域-->
      <div class="query-main-bottom" :style="{ height: bottemHeight }">
        <!--按钮插槽-->
        <div id="buts">
          <slot name="buts" />
        </div>
        <!--表格插槽-->
        <div id="tableBody" style="background: white">
          <el-table
            v-loading="queryData.loading"
            :data="queryData.list"
            row-key="id"
            stripe
            border
            :height="queryData.tableHeight"
            element-loading-text="加载中..."
            style="width: 98%; overflow: auto; margin-left: 1%; top: 0.0417rem"
            :span-method="spanMethod"
          >
            <el-table-column
              v-if="indexVisible"
              type="index"
              label="序号"
              align="center"
              width="80"
            />
            <slot name="table" />
          </el-table>
          <!-- 分页 -->
          <div id="pagination" style="text-align: center">
            <pagination
              v-if="showPagination"
              v-model:current="queryData.current"
              v-model:size="queryData.size"
              :total="queryData.total"
              @pagination="queryPagination"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watch, computed, nextTick, onMounted } from 'vue';
import elementResizeDetectorMaker from 'element-resize-detector';

//申明emit事件
const emits = defineEmits(['update:modelValue', 'query', 'reset']),
  //接受父组件传入参数
  props = defineProps({
    modelValue: {
      type: Object,
      default: null
    },
    /** 显示头部 */
    showTop: {
      type: Boolean,
      default: true
    },
    /** 显示分页 */
    showPagination: {
      type: Boolean,
      default: true
    },
    /** 分页位置 left right center */
    paginationAlign: {
      type: String,
      default: 'center'
    },
    /** 显示查询按钮 */
    showQueryBut: {
      type: Boolean,
      default: true
    },
    /** 显示重置按钮 */
    showResetBut: {
      type: Boolean,
      default: true
    },
    mainTop: {
      type: Number,
      default: 0
    },
    //是否显示排序
    indexVisible: {
      type: Boolean,
      default: true
    },
    //是否显示机构名称
    orgVisible: {
      type: Boolean,
      default: true
    },
    //合并行或合并列
    spanMethod: {
      type: Object,
      default: null
    }
  });

//申明响应式参数
let queryData = reactive(props.modelValue),
  isAll = ref(false),
  isShow = ref(false),
  maxHeight = ref('0.2344rem'),
  mainTop = ref(props.mainTop),
  bottemHeight = ref('0rem'), //底部内容区域高度
  butWidth = ref('0rem'),
  mainHeight = ref(0), //全部内容区域高度
  queryTopHeight = ref(0), //头部查询区域高度
  butsBodyHeight = ref(0), //按钮区域高度
  paginationHeight = ref(0); //分页区域高度

//监听函数
watch(
  () => queryData,
  (newValue, oldValue) => {
    emits('update:modelValue', newValue);
  },
  {
    immediate: true, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);
//设置表格高度
// eslint-disable-next-line one-var
const setTableHeight = () => {
    //1.获取内容区域高度
    if (document.getElementById('main')) {
      mainHeight.value = document.getElementById('main').clientHeight;
    }
    /**2.获取查询区域高度 */
    if (props.showTop) {
      if (document.getElementById('queryTop')) {
        queryTopHeight.value = document.getElementById('queryTop').clientHeight;
        let scrollHeight = document.getElementById('queryTop').scrollHeight;

        isShow.value = scrollHeight > 50;
        maxHeight.value = scrollHeight + 'px';
      }
      if (document.getElementById('quyBut').clientWidth) {
        butWidth.value = document.getElementById('quyBut').clientWidth + 'px';
      }
    }
    //3.获取按钮区域高度
    // eslint-disable-next-line one-var
    if (document.getElementById('buts')) {
      butsBodyHeight.value = document.getElementById('buts').clientHeight;
    }
    /**4.获取查询区域高度 */
    if (document.getElementById('pagination')) {
      paginationHeight.value =
        document.getElementById('pagination').clientHeight;
    }
    /**底部内容高度 = 内容区域高度 - 查询区域高度 - 查询区域上下边距高度 15px - 距离底部边距高度 15px */
    // eslint-disable-next-line one-var
    let bottome = mainHeight.value - queryTopHeight.value - mainTop.value - 45;
    /**表格高度 =底部内容高度 - 按钮区域高度-分页插件高度 -分页插件上留25px下各留10px,按钮上下各留10px, 总共55px */

    bottemHeight.value = bottome + 'px';
    // eslint-disable-next-line one-var
    let tableHeight =
      mainHeight.value -
      queryTopHeight.value -
      butsBodyHeight.value -
      paginationHeight.value -
      55;

    queryData.tableHeight = tableHeight;
  },
  // 监听div高度变化
  tang = () => {
    const erd = elementResizeDetectorMaker();

    erd.listenTo(document.getElementById('buts'), () => {
      nextTick(() => {
        setTableHeight();
      });
    });
    erd.listenTo(document.getElementById('tableBody'), () => {
      nextTick(() => {
        setTableHeight();
      });
    });
    if (props.showTop) {
      // 监听div高度变化
      erd.listenTo(document.getElementById('queryTop'), () => {
        nextTick(() => {
          setTableHeight();
        });
      });
      erd.listenTo(document.getElementById('quyBut'), () => {
        nextTick(() => {
          setTableHeight();
        });
      });
    }
    // 监听窗口高度变化
    window.onresize = () => {
      return () => {
        setTableHeight();
      };
    };
  },
  //查询
  query = () => {
    emits('query');
  },
  //页码改变
  queryPagination = (data) => {
    emits('query');
  },
  //重置
  reset = () => {
    queryData.queryEntity = {};
    queryData.current = 1;
    queryData.dateTimeRange = null;
    queryData.size = 10;
    queryData.total = 0;
    queryData.adminCode = null;
    queryData.orgCode = null;
    emits('reset');
  };

computed(() => {
  // 监听div高度变化
  return tang();
});
onMounted(() => {
  tang();
});
</script>
<style lang="scss" scoped>
//页面主体
.query {
  position: absolute;
  height: 100%;
  width: 100%;
  //注内容区域
  &-main {
    position: absolute;
    padding: 0 0.0833rem 0.0521rem 0.0833rem;
    width: calc(100% - 0.1563rem);
  }
}

//表格与新增按钮
.query-main-bottom {
  position: absolute;
  width: calc(100% - 0.1563rem);
  background-color: white;
  margin-top: 0.0521rem;
  border-radius: 0.0313rem;
}
//新增按钮区域
#buts {
  background-color: white;
  border-radius: 0.0313rem;
}
</style>
<style lang="scss">
/**查询区域*/
.query-main-top {
  background-color: #ffffff;
  border-radius: 5px;
  .el-scrollbar__wrap {
    min-height: 125px;
    max-height: 175px;
    overflow-x: hidden !important;
  }
}

//查询区域内部
.query-scrollbar {
  overflow: hidden;
  position: relative;
  min-height: 100px;
  margin-top: 10px;
  .left {
    position: absolute;
  }
  .right {
    position: absolute;
    right: 5px;
    text-align: center;
  }
  .buttom {
    margin-left: 10px;
    color: #3ea88d;
  }
}
</style>
