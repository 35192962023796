/** 实时数据类别 */
import { Entity } from '@/model';
const dataCategory = new Entity({
  /** 父ID*/
  parentId: String,
  /** 全名*/
  fullName: String,
  /** 名称*/
  name: String,
  /** 层级*/
  level: Number,
  /** 排序*/
  sortNumber: Number
});

export default dataCategory;
