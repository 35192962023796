<!-- 表单下拉选择框 -->
<template>
  <el-select
    v-model="val"
    :size="size"
    :disabled="disabled"
    :multiple="multiple"
    :style="{'width':width}"
    clearable
    filterable
    :class="className"
    :placeholder="placeholder"
  >
    <template v-for="(item,key) in list">
      <template v-if="!!list && list.length >0">
        <el-option :key="key" :label="item[labelName]" :value="item[valueName]" />
      </template>
    </template>
  </el-select>
</template>
<script setup>
import {ref,watch,onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import queryEntity from '@/model/entity/framework/queryEntity';

const store = useStore(),
  emits = defineEmits(['update:modelValue','change']),
  props= defineProps({
    modelValue: {
      type: String,
      default: ''
    },
    //输入框尺寸
    size: {
      type: String,
      default: 'default'
    },
    //请求接口地址 例如 `/sys/user/queryList`
    api: {
      required: true,
      type: String,
      default: null
    },
    //查询条件的key
    queryKey: {
      required: false,
      type: String,
      default: null
    },
    //查询条件的value
    queryValue: {
      required: false,
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    className: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: ''
    },
    //label的取值字符
    labelName: {
      type: String,
      default: 'name'
    },
    //value的取值字符
    valueName: {
      type: String,
      default: 'id'
    }
  }),

  val = ref(),
  queryData= queryEntity.parse({}),
  list=ref([]);

// eslint-disable-next-line one-var
const initData= async () =>{
  let dataList=store.getters.getDatatList(props.api);

  if(dataList && dataList.length>0){
    list.value =dataList;
  }else{
    let params={
      api:props.api
    };

    if(props.queryKey && props.queryValue){
      const keyList=props.queryKey.split(','),
        valueList=props.queryValue.split(',');

      if(keyList && keyList.length>0 && valueList && valueList.length>0 && keyList.length === valueList.length){
        // eslint-disable-next-line no-undef
        keyList.forEach((key,index)=>{
          queryData.queryEntity[key]= valueList[index];
        });
      }
    }
    params.queryData=queryData;
    const {data} = await store.dispatch('GetDatatList',params);

    list.value =data;
  }
};


//监听函数
watch(
  ()=> props.modelValue,(newValue,preValue)=> {
    val.value=newValue;
  },
  {
    immediate:true,//watch会在创建后立即执行一次
    deep:true //监听深度嵌套的对象属性
  });
//监听函数
watch(
  ()=> val.value,(newValue,preValue)=> {
    emits('update:modelValue', newValue);
    if (newValue && list.value && list.value.length > 0) {
      emits('change', list.value.find(r => r.id === newValue));
    }
  },
  {
    immediate:true,//watch会在创建后立即执行一次
    deep:true //监听深度嵌套的对象属性
  });

onBeforeMount(()=>{
  initData();
});

</script>
